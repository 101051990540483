import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

export default function NotFound() {
  return (
    <Layout>
      <h3>Opps! There's nothing here...</h3>
      <Message>
        Here's a <Link to="/">🪵</Link> to bring you back.
      </Message>
    </Layout>
  );
}

const Message = styled.div`
  > a {
    text-decoration: none;
  }
`;
